.l-site-header,
.l-page {
	max-width: 100%;
}

.l-site-header {
	padding: 0;
}

.contours {
	display: none;
}

.bid-fold {
	padding-bottom: 2rem;
}

.bid-body {
	&__nav {
		display: none;
	}

	&__content > section {
		page-break-inside: avoid;
	}
}

.entity-list {
	&__link-text {
		& span:last-child {
			display: none;
		}
	}

	&-item {
		page-break-inside: avoid;
	}
}

picture,
img {
	page-break-inside: avoid;
}

button[data-print] {
	display: none;
}

.l-dashboard {
	table {
		max-width: 100%;
	}
	td {
		font-size: 12px !important;

		&.no-break {
			white-space: wrap;
		}
	}
}
